'use client';

// React & Next
import { useEffect } from 'react';

// 3rd
import { Flex, Link } from '@chakra-ui/react';

// App - Other
import Locale from '@/locale/en.json';
import { DASHBOARD_ROUTES } from '@/config/routes';
import { ErrorOldIllustration, RefreshIcon, ArrowIcon } from '@/components/atoms/icon';
import { Heading } from '@/components/atoms/typography';
import { Button } from '@/components/molecules/button';
import { AppLayout } from '@/components/layouts/app';
import { FlexiblePageLayout } from '@/components/layouts/page';

const locale = Locale.pages.error;

type ErrorPageProps = {
  error: Error & { digest?: string };
  reset: () => void;
};

export default function Error({ error, reset }: ErrorPageProps) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <AppLayout justifyContent="center">
      <FlexiblePageLayout>
        <FlexiblePageLayout.Content alignItems="center" justifyContent="center" gap="3xl" h="90%">
          <Heading variant="h3">{locale['Whoops, something went wrong']}</Heading>

          <ErrorOldIllustration maxW="272px" w="auto" h="auto" ms="86px" />

          <Flex alignItems="center" gap="lg">
            <Button
              variant="old.outline"
              leftIcon={<RefreshIcon aria-label={locale['Try again']} />}
              onClick={() => reset()}
              aria-label={locale['Try again']}
            >
              {locale['Try again']}
            </Button>

            <Link href={DASHBOARD_ROUTES.dashboard()} _hover={{ textDecoration: 'none' }}>
              <Button
                variant="old.solid"
                leftIcon={<ArrowIcon aria-label={locale['Go home']} />}
                aria-label={locale['Go home']}
              >
                {locale['Go home']}
              </Button>
            </Link>
          </Flex>
        </FlexiblePageLayout.Content>
      </FlexiblePageLayout>
    </AppLayout>
  );
}
